import React from "react"
import Image from "gatsby-image"
import Layout from "../components/layout"
import CustomGallery from "../components/gallery"

import "./styles.css"

export default function Specialist({ pageContext }) {
  const gallery2 = pageContext.details.gallery1.map((node) => node.childImageSharp)
  return (
    <Layout>
      <div
        className='specialist-image specialist-background'
        style={{ backgroundImage: `url('${pageContext.image.childImageSharp.fluid.src}')` }}
      />
      <div className='specialist-container'>
        <Image
          fluid={pageContext.image.childImageSharp.fluid}
          className='specialist-image'
        />
        <header>
          <h3>{pageContext.name}</h3>
          <div>{pageContext.degree}</div>
        </header>
        <div dangerouslySetInnerHTML={{ __html: pageContext.details.description1 }}/>
        <CustomGallery images={gallery2}/>
        <div dangerouslySetInnerHTML={{ __html: pageContext.details.description2 }}/>
        <div dangerouslySetInnerHTML={{ __html: pageContext.details.description3 }}/>
      </div>
    </Layout>
  )
}
